import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mantine/core'
import styles from './PageHeader.module.scss'
import Button from '../common/Button'
import { ChevronLeft } from 'react-feather'
import { Link } from 'gatsby'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid'

const PageHeader = ({ title, date, author, featuredImage, topic }) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            <h1>{title}</h1>
          </div>
        </div>
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
