import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Space, TextInput } from '@mantine/core'
import styles from './Article.module.scss'
import Button from '../common/Button'
import { ChevronLeft } from 'react-feather'
import { Link } from 'gatsby'
import { stringify } from 'qs'
import { serialize } from 'dom-form-serializer'
import Content from './Content'
import ApplyForm from './ApplyForm'

const Article = ({ title, location, remote, partTime, body }) => {
  return (
    <div className={styles.layout}>
      <div className={styles.headerContent}>
        <div className={styles.heading}>
          <h1>{title}</h1>
          <p>
            {location} / {remote ? 'Remote' : 'On-site'} /{' '}
            {partTime ? 'Part-time' : 'Full-time'}
          </p>
          <Button href="#apply">Apply for this role</Button>
        </div>
      </div>
      <div>
        <div className={styles.content}>
          <article itemScope itemType="http://schema.org/BlogPosting">
            <Content source={body} />
          </article>
        </div>
        <div id="apply" className={styles.applyContainer}>
          <ApplyForm position={title} />
        </div>
      </div>
    </div>
  )
}

export default Article
