import React, { Fragment, useState } from 'react'
import styles from './ApplyForm.module.scss'
import Button from '../common/Button'
import { FileIcon } from '@atlas-co/icons'

const ApplyForm = ({ position }) => {
  const defaultProps = {
    name: 'Employee Applications',
    subject: '',
    action: '',
    errorMessage:
      'There is a problem, your application has not been sent, please try contacting us via email at contact@atlas.co.'
  }
  const [fileName, setFileName] = useState('')
  const [fileError, setFileError] = useState('')

  const handleFileChange = event => {
    setFileError('')
    const file = event.target.files[0]
    const maxSize = 2 * 1024 * 1024 // 2MB in bytes

    if (file) {
      if (file.size > maxSize) {
        setFileError('File size exceeds 2MB. Please upload a smaller file.')
        setFileName('')
        event.target.value = ''
      } else {
        setFileName(file.name)
      }
    }
  }

  const [alert, setAlert] = useState('')
  const [disabled, setDisabled] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    if (disabled) return

    const form = e.target
    const formData = new FormData(form)
    formData.append('position', position)

    setDisabled(true)
    setAlert('Submitting your application...')

    try {
      const response = await fetch(form.action, {
        method: 'POST',
        body: formData
      })

      if (!response.ok) {
        throw new Error('Network error')
      }

      form.reset()
      setFileName('')
      setAlert('Thank you for your application! We will get back to you soon.')
    } catch (err) {
      console.error(err)
      setAlert(defaultProps.errorMessage)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <Fragment>
      <form
        className={styles.form}
        name="Employee Applications"
        method="POST"
        action={defaultProps.action}
        onSubmit={handleSubmit}
        data-netlify="true"
        encType="multipart/form-data"
      >
        {alert && <div className={styles.formAlert}>{alert}</div>}

        <label className={styles.formLabel}>
          <span>Name*</span>
          <input
            className={`${styles.formInput} ${styles.formInputText}`}
            type="text"
            placeholder="John Smith"
            name="name"
            required
          />
        </label>

        <label className={styles.formLabel}>
          <span>Email*</span>
          <input
            className={`${styles.formInput} ${styles.formInputText}`}
            type="email"
            placeholder="john@company.com"
            name="email"
            required
          />
        </label>

        <label className={styles.formLabel}>
          <span>Portfolio URL</span>
          <input
            className={`${styles.formInput} ${styles.formInputText}`}
            type="url"
            placeholder="https://example.com"
            name="portfolio"
          />
        </label>

        <label className={styles.formLabel}>
          <span>Resume</span>
          <input
            className={`${styles.formInput} ${styles.formInputFile}`}
            type="file"
            name="resume"
            accept=".pdf,.doc,.docx"
            onChange={handleFileChange}
          />
          <div>
            <Button variant="outline" leftIcon={<FileIcon />}>
              Upload
            </Button>
            {fileName && (
              <span style={{ marginLeft: '0.5rem' }}>{fileName}</span>
            )}
            {fileError && <span style={{ color: '#A73636' }}>{fileError}</span>}
          </div>
        </label>

        <label className={styles.formLabel}>
          <span>Why do you want to join us?</span>
          <textarea
            className={`${styles.formInput} ${styles.formInputText} ${styles.textArea}`}
            placeholder="Send an engaging message and tell us why you want to join us"
            name="message"
          />
        </label>

        <input type="hidden" name="form-name" value="Employee Applications" />
        <input
          className={styles.button}
          type="submit"
          value="Apply"
          disabled={disabled}
        />
      </form>
    </Fragment>
  )
}

export default ApplyForm
